//Common
.home__header {
    position: relative;
    max-height: 100vh;
    min-height: 100vh;
    &--image {
        display: block;
        width: 100%;
        height: auto;
        min-height: 100vh;
        max-height: 100vh;
        object-fit: cover;
    }

    &--logo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .header-icons {
        display: flex;
        justify-content: space-between;
        max-width: 962px;
        margin: 0 auto;
        position: absolute;
        bottom: 30px;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
    }
}

//Mobile
@media (max-width: 768px) {
    .home__header {
        &--logo {
            display: none;
        }
    }
    .slider {
        position: absolute;
        bottom: 30px;
        overflow: hidden;
        width: 100%;

        .slick-slide {
            opacity: .3;
            transition: .3s;
            &.slick-active {
                opacity: 1;
            }
        }
    }
}