// Common
.about-images {
  padding-top: 170px;
  &__row {
    img {
      display: block;
      width: 100%;
      height: 100%;
      transition: .5s;
    }
  }

  span {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      transition: .5s;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }

      &::after {
        background-color: transparent;
      }
    }
  }
}

// Desktop
@media (min-width: 768px) {
  .about-images {
    &__row {
      display: flex;
      img {
        object-fit: cover;
      }
      span {
        cursor: pointer;
        max-height: 720px;
      }
      &:first-of-type {
        span {
          width: 25%;
        }
      }
      &:nth-of-type(2) {
        span {
          width: 50%;
        }
      }
      &:nth-of-type(4) {
        max-height: 720px;
        span:first-of-type {
          width: 70%;
        }
        span:last-of-type {
          width: 30%;
        }
      }
    }
  }
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  cursor: pointer;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
    transition: transform .3s;
    width: auto;
    &:hover {
      transform: scale(1.3);
    }
  }

  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }

  .prev-button,
  .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    background: none;
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: transform .3s;
  }

  .prev-button {
    left: 20px;
    &:hover {
      transform: translateY(-50%) scale(1.3);
    }
  }

  .next-button {
    right: 20px;
    transform: translateY(-50%) rotate(180deg);
    &:hover {
      transform: translateY(-50%) scale(1.3) rotate(180deg);
    }
  }
}
