//Common
.splashscreen {
    position: relative;

    &__bg {
        display: block;
    }

    &__center {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

//Mobile
@media (max-width: 768px) {
    .splashscreen {
        &__bg {
            height: 50vh;
            width: 100%;
            object-fit: cover;

            &.initial {
                display: none;
            }
        }

        &__center {
            flex-direction: column;

            &--divider {
                display: none;
            }
        }

        &__trigger {
            display: inline-flex;
            height: 50vh;

            img {
                &.naturmedicin-logo {
                    max-width: 240px;
                }

                &.czarter-logo {
                    max-width: 110px;
                }
            }
        }
    }
}

//Desktop
@media (min-width: 768px) {
    .splashscreen {    
        &__bg {
            position: absolute;
            top: 0;
            display: block;
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            transition: .3s;
    
            &.czarter,
            &.naturmedicin {
                opacity: 0;
                z-index: 2;
                pointer-events: none;
    
                &.active-bg {
                    opacity: 1;
                }
            }
        }
    
        &__center {
            height: 100vh;
            width: 95vw;
            margin: 0 auto;
    
            &--divider {
                padding: 0 200px;
                z-index: 3;
            }

            img {
                width: 100%;
            }
        }
    
        &__trigger {
            z-index: 3;
        }
    }
}