//Common
.whyus {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 50px 0 93px;
  &__title {
    margin: 36px 0 18px;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  &__content {
    color: #7D617D;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  &__item {
    padding-top: 70px;
  }
}

//Desktop
@media (min-width: 768px) {
  .whyus {
    padding: 140px 10px 0 10px;
    max-width: 962px;
    margin: 0 auto;
    justify-content: space-between;
    &__item {
      padding-top: 110px;
      max-width: 360px;
    }
  }
}