.list-wrapper {
    padding: 10px 0;

    &__title {
        display: block;
        font-weight: 900;
        font-size: 18px;
        margin-bottom: 5px;
    }

    &__ul {
        margin: 0;
        padding-left: 10px;
    }
}