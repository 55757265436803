//Common
.mainnav {
    position: fixed;
    top: 0;
    width: 100%;
    background: #262626;
    text-align: center;
    z-index: 11;
    transform: translateY(-500px);
    transition: transform .6s;

    &.active {
        transform: translateY(0);
    }

    .nav-logo {
        width: 100%;
        height: 100%;
        display: block;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 962px;
        height: 88px;
        margin: 0 auto;

        &--logo {
            img {
                max-width: 162px;
            }
        }

        &--nav {
            &.active {
                transform: translateX(0);
            }
        }

        &--links {
            display: flex;
            list-style: none;

            li {
                font-weight: 700;
                margin-right: 20px;
    
                a {
                    color: #D9D9D9;
                    font-size: 18px;
                    letter-spacing: 1.8px;
                    text-decoration: none;
                    text-transform: uppercase;
                    position: relative;

                    &:hover {
                        span::after {
                            width: 100%;
                            opacity: 1;
                        }
                    }
                }

                span {
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -5px;
                        background: #D9D9D9;
                        display: block;
                        height: 1px;
                        width: 0;
                        opacity: 0;
                        transition: .4s;
                        z-index: 100;
                    }
                }
            }
        }
    }
}

//Mobile
@media (max-width: 768px) {
    .mainnav {
        transform: translateY(0);
        background-color: transparent;
        &__wrapper {
            max-width: 80%;
            &--nav {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                height: 100%;
                width: 100%;
                position: fixed;
                z-index: 101;
                top: 0;
                left: 0;
                overflow-x: hidden;
                transform: translateX(-100%);
                opacity: 0;
                transition: .5s;

                &.active {
                    opacity: 1;
                    transform: translateX(0);
                    height: 100vh;
                    width: 100vw;
                }
            }

            &--close {
                position: absolute;
                top: 8px;
                right: 7%;
                cursor: pointer;
                color: #fff;
                display: block;
                font-size: 40px;
                padding: 10px;
                text-decoration: none;
                transition: transform 0.3s;
            }

            &--links {
                flex-direction: column;
                margin-bottom: 100px;
                margin-right: 30px;
                gap: 50px;
                text-align: right;
            }
        }
    }

    .naturmedicin {
        .mainnav {
            &__wrapper {
                &--nav {
                    background-color: rgba(41, 28, 41, 0.95);
                }
            }
        }
    }

    .yacht {
        .mainnav {
            &__wrapper {
                &--nav {
                    background-color: rgba(35, 41, 42, 0.95);
                }
            }
        }
    }
}

//Desktop
@media (min-width: 768px) {
    .mainnav {
        box-shadow: 0 0 15px -3px rgba(0, 0, 0, .2);

        &__wrapper {
            &--hamburger,
            &--close {
                display: none;
            }

            &--links li a {
                font-size: 12px;
            }
        }
    }
}