//Common
.footer {
    display: flex;
    flex-wrap: wrap;
    gap: 160px;
    padding: 200px 0 ;

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;
        width: 100%;
        padding-left: 93px;
        h3 {
            color: #C3C3C3;
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 2.7px;
            text-transform: uppercase;
        }
        p {
            color: #CCC;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            a {
                color: #CCC;
                text-decoration: none;
            }
        }
    }
}

//Desktop
@media (min-width: 768px) {
    .footer {
        max-width: 962px;
        margin: 0 auto;
        justify-content: space-between;
        padding: 300px 10px;
        &__column {
            width: auto;
            padding-left: 0;
        }
    }
}