//Common
.about-our-agency {
  padding: 200px 50px 0 90px;
  &__title {
    display: flex;
    flex-direction: column;
    color: #C3C3C3;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.3px;
    text-transform: uppercase;
    span {
      color: #644E64;
      font-size: 14px;
      font-weight: 500;
      line-height: 130.023%;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
  }
  &__content {
    padding-top: 32px;
    padding-bottom: 30px;
    color: #CCC;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  &__icons {
    padding: 50px 0 0 0;
    display: flex;
    gap: 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.yacht {
  .about-our-agency {
    &__title span {
      color: #646C78;
    }
  }
}

//Desktop
@media (min-width: 768px) {
  .about-our-agency {
    display: flex;
    justify-content: space-between;
    max-width: 962px;
    margin: 0 auto;
    padding: 260px 10px 0 10px;
    &__title {
      gap: 7px;
    }
    &__content {
      padding-top: 0;
      max-width: 500px;
    }
    &__icons {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}