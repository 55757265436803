.offerinfo-wrapper {
    &__content {
        &-price {
            padding: 10px 0;
        }

        ul {
            list-style-type: none;
            padding-left: 10px;

            li {
                position: relative;

                &::before {
                    content: "-";
                    position: absolute;
                    left: -10px;
                }
            }
        }
    }

    &:first-of-type {
        border-bottom: 1px solid;
    }
}