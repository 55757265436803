html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

.yacht {
  background-color: #23292A;;
}

.naturmedicin {
  background-color: #291C29;
}

.cursor-pointer {
  cursor: pointer;
}