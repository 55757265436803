//Common
.error_page {
    text-align: center;

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh
    }

    &--header {
        font-size: 40px;
        margin: 0;
        padding: 20px 0;
    }

    &--back {
        padding: 20px 0;

        a {
            color: #23292A;

            &:hover,
            &:focus,
            &:visited {
                color: #23292A;
            }
        }
    }
}